<template>
  <div class="info-page">
    <van-nav-bar title="传感器数据" left-text="返回" left-arrow @click-left="onClickLeft"/>
    <div class="glo-content">
        <div v-if="loading">
            <van-loading type="spinner" vertical>加载中</van-loading>
        </div>
        <div v-else>
            <van-collapse v-model="activeNames" accordion>
                <van-collapse-item v-for="(item,index) in sData" :key="index" :title="item.devName || '暂无名称'" :name="index">
                    <van-cell title="设备编号" :value="item.devCode" />
                    <van-cell title="设备类型" value="振动模块" />
                    <van-cell title="设备时间" :value="(item.cycleData && item.cycleData.uploadTime) || item.updateTime" />
                    <van-cell title="挠度" :value=" (item.cycleData && item.cycleVibrateDataData &&  (item.cycleVibrateDataData.vibAmp / 1).toFixed(2) || 0 )+' mm'" />
                    <van-cell title="倾角" :value="(item.cycleData && item.cycleData.curAnglez || 0 )+' °'" />
                    <van-cell title="加速度" :value="(item.cycleData && item.cycleData.cycleVibrateDataData && item.cycleData.cycleReserveData.accZ || 0 ) +' mg'" />
                    <van-cell title="电池电压" :value="((item.cycleData && item.cycleData.batVolt || 0) * 0.01) +' V'" />
                    <van-cell title="工作温度" :value="(item.cycleData && item.cycleData.chipTemp || 0) +' ℃'" />
                    <van-cell title="信号强度" :value="item.cycleData && item.cycleData.rsrp || 0" />
                </van-collapse-item>
            </van-collapse>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        activeNames:0,
        projectCode:'',
        loading:true,
        sData:[]
    }
  },
  methods:{
      onClickLeft(){
        this.$router.go(-1);//返回上一层
      },
      // 获取设备传感器信息
      getData(){
          this.$api.NEWBRIDGE.sensorDetail({
              projectCode:this.projectCode
          }).then( d => {
              this.sData = d.filter( v => v);
              this.loading = false;
          })
      }
  },
  mounted(){
      document.querySelector('body').setAttribute('style', 'background-color:#f0f0f0');
      this.projectCode = window.sessionStorage.getItem('projectCode');
      this.projectCode ? this.getData() : void 0;
  },
  beforeDestroy() {
      document.querySelector('body').removeAttribute('style')
}
}
</script>
<style lang="scss" scoped>
.van-cell__title{text-align: left;}
.van-cell__value {
    -webkit-box-flex: inherit;
    -webkit-flex: inherit;
    flex: inherit;
    display: inherit;
}
.van-loading--vertical {
    margin: 50% auto;
}
</style>